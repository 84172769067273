@font-face {
font-family: 'AvenirLight';
src: url('./fonts/avenir/Avenir-Light-07.ttf');
}

@font-face {
font-family: 'AvenirRoman';
src: url('./fonts/avenir/Avenir-Roman-12.ttf');
}

@font-face {
font-family: 'AvenirBook';
src: url('./fonts/avenir/Avenir-Book-01.ttf');
}

@font-face {
font-family: 'AvenirMedium';
src: url('./fonts/avenir/Avenir-Medium-09.ttf');
}

@font-face {
font-family: 'AvenirHeavy';
src: url('./fonts/avenir/Avenir-Heavy-05.ttf');
}

@font-face {
font-family: 'Valencia';
src: url('./fonts/valencia/Valencia.ttf');
}